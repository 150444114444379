/**
 * @file index.css
 * @description This file contains the global styles for the application.
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Regular.woff2");
}

@font-face {
  font-family: "HelveticaNowText";
  src: url("./fonts/HelveticaNowText.woff2");
}

@font-face {
  font-family: "SKPayidar";
  src: url("./fonts/SKPayidar.woff2");
}

@font-face {
  font-family: "Icons";
  src: url("./fonts/Icons.woff");
}

:root {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #333;
}

::selection {
  text-shadow: none;
  color: #fff;
  background: #0794d9;
}

body {
  overflow-y: scroll;
}

li::marker {
  font-size: 1rem;
}

.flg span,
.flgR span,
.flgB span,
.flgG span,
.flgP span {
  display: inline-block !important;
}

.flg span::first-letter {
  color: #0794d9;
}

.flg em,
.flgR em,
.flgG em,
.flgB em,
.flgP em {
  font-style: normal;
  color: #0794d9;
}

.flgR span::first-letter {
  color: #eb393a;
}

.flgB span::first-letter {
  color: #1f87d6;
}

.flgP span::first-letter {
  color: #6725f5;
}

.flgG span::first-letter {
  color: #3cb043;
}

.no-scroll {
  overflow: hidden;
}

:disabled {
  cursor: not-allowed;
  filter: grayscale(0.75);
}

@layer components {
  .itemHeader {
    @apply relative h-full items-center flex text-[#f2f2f2];
  }

  .itemHeader.active::before,
  .itemHeader.active:hover::after {
    content: "";
    @apply bottom-[5px] left-[0] absolute bg-[#0794d9] w-[10px] h-[10px] rounded-[50%];
  }

  .itemHeader:hover::after {
    content: "";
    @apply bottom-[5px] left-[0] absolute w-[10px] h-[10px] rounded-[50%] border-[#0794d9] bg-[#121212] border-[2px];
  }

  .input {
    @apply min-h-[50px] bg-[#161616] border-[1px] border-[#2d2d2d] text-[#ffffff] text-[1rem] px-[15px] rounded-[6px] outline-none focus:border-[#0794d9] focus:border-[2px] placeholder-[#747474];
  }

  .contactCard {
    @apply bg-[#000000] text-[#f3f3f3] border-[1px] border-[#272727] min-h-[100px] md:grid md:grid-cols-[120px_1fr] flex justify-center items-center flex-col text-[1.3rem] py-[20px] rounded-[16px] shadow-[0_16px_10px_0_rgba(0,0,0,.1)] sm:text-[1.1rem] w-full max-w-[600px];
  }

  .contactCard:nth-child(2n) {
    @apply justify-self-start;
  }

  .contactCard:nth-child(2n + 1) {
    @apply justify-self-end;
  }

  .icon {
    @apply text-[#0794d9];
  }

  .icon.i-tvmux::before {
    content: "";
  }

  .icon.i-ipmux::before {
    content: "";
  }

  .icon.i-infobox::before {
    content: "";
  }

  .icon.i-cablebox::before {
    content: "";
  }

  .icon.i-vnubebox::before {
    content: "";
  }

  .icon.i-mini::before {
    @apply text-[6rem] p-[30px];
  }

  .icon::before {
    content: "";
    font-family: "Icons";
    font-style: normal;
    @apply text-[8rem] p-[50px] border-[#ccd1d8] border-[4px] rounded-[50%];
  }

  .check:nth-child(2n + 1) {
    @apply border-r-[1px] border-[#ccc] xs:border-none;
  }

  .check > p:first-of-type {
    @apply inline;
  }
}
